import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { getChecklists } from './queries/getChecklists'
import { getHeaders } from '../../../../(deprecated)/utils/axios/getHeaders'
import { getCheckListById } from './queries/getCheckListById'
import Host from '../../../../(deprecated)/utils/axios/host'

const basequery = fetchBaseQuery({
  baseUrl: Host.getApiUrlSM(),
  prepareHeaders: async (Headers: Headers) => {
    Object.entries(await getHeaders())
      .forEach((header) => Headers.set(...header))
    return Headers
  }
})

export const checklistsApi = createApi({
  reducerPath: 'checklistsApi',
  baseQuery: basequery,
  tagTypes: ['checklists-api'],
  endpoints: (builder) => ({
    getList: getChecklists(builder),
    getById: getCheckListById(builder)
  })
})

export const {
  useGetListQuery,
  useLazyGetListQuery,
  useLazyGetByIdQuery,
} = checklistsApi