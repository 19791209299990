import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RemarkStatus, RemarkType, RemarkTypeWithLink } from '../../../entities/Remarks/types/types'

export const remarksSlice = createSlice({
  name: 'remarksSlice',
  initialState: {
    remarks: null as null | RemarkTypeWithLink[],
    activeRemark: null as null | RemarkTypeWithLink,
    remarksUserIds: [] as number[],
    remarksUsers: [] as any[]
  },
  reducers: {
    setRemarks(state, { payload }: PayloadAction<RemarkType[]>) {
      const remarksWithLinks = payload.map(remark => ({
        ...remark,
        link: ` https://sm-lahta.liis.su/#/show_issue?uuid=${remark.uuid}&type=${remark.issueType.toLowerCase()}`
      }))
      if (!state.remarks) {
        state.remarks = remarksWithLinks
        return
      }
      state.remarks = [...state.remarks, ...remarksWithLinks]
    },
    setActiveRemark(state, { payload }: PayloadAction<RemarkTypeWithLink | null>) {
      state.activeRemark = payload
    },
    updateRemarkStatus(state, { payload }: PayloadAction<RemarkStatus>) {
      if (state.activeRemark) {
        state.activeRemark.status = payload
      }

      if (state.remarks && state.activeRemark) {
        const remark = state.remarks.find(({ uuid }) => uuid === state.activeRemark?.uuid)
        if (remark) {
          remark.status = payload
        }
      }
    },
    clearRemarks(state) {
      state.remarks = null
    },
    setRemarksUsersId(state, { payload } : PayloadAction<number[]>) {
      state.remarksUserIds = Array.from(new Set([...state.remarksUserIds, ...payload]))
    },
    // TODO убрать any
    setRemarksUsers(state, { payload } : PayloadAction<any[]>) {
      state.remarksUsers = [...state.remarksUsers, ...payload]
    }
  }
})


export const {
  setRemarks,
  setActiveRemark,
  updateRemarkStatus,
  clearRemarks,
  setRemarksUsersId,
  setRemarksUsers
} = remarksSlice.actions

export default remarksSlice.reducer