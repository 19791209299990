import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { getHeaders } from '../../../../(deprecated)/utils/axios/getHeaders'
import { getAllRemarks } from './queries/getAllRemarks'
import { updateRemark } from './queries/updateRemark'
import { getModelRemark } from './queries/getModelRemark'
import { getDrawingRemark } from './queries/getDrawingRemark'
import Host from '../../../../(deprecated)/utils/axios/host'

const baseQuery = fetchBaseQuery({
  baseUrl: Host.getApiUrlSM(),
  prepareHeaders: async (Headers: Headers) => {
    Object.entries(await getHeaders())
      .forEach((header) => Headers.set(...header))
    return Headers
  }
})
export const remarksApi = createApi({
  reducerPath: 'remarksApi',
  baseQuery,
  tagTypes: ['remarks-api'],
  endpoints: (builder) => ({
    getList: getAllRemarks(builder),
    updateRemark: updateRemark(builder),
    getModelRemark: getModelRemark(builder),
    getDrawingRemark: getDrawingRemark(builder)
  })
})

export const useLazyGetListQuery = remarksApi.useLazyGetListQuery
export const useUpdateRemarkMutation = remarksApi.useUpdateRemarkMutation
export const useLazyGetModelRemarkQuery = remarksApi.useLazyGetModelRemarkQuery
export const useLazyGetDrawingRemarkQuery = remarksApi.useLazyGetDrawingRemarkQuery